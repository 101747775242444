<template>
  <section class="c-coconalaMerit">
    <content-headline
      title-tag="h2"
      title-text="ココナラのメリット"
    />
    <div
      class="c-meritList"
      data-translate
    >
      <div class="c-meritItem">
        <div class="c-meritHeadline">
          <img
            class="c-meritHeadline_tag"
            src="~/assets/images/components/HomeMainContent/CoconalaMerit/tag_point1.svg"
            alt="POINT 1"
            loading="lazy"
            decoding="async"
          />
          <div class="c-meritHeadline_title">比較してお得</div>
        </div>
        <div class="c-meritContent">
          <img
            class="c-meritContent_image"
            src="~/assets/images/components/HomeMainContent/CoconalaMerit/coconala_merit1.jpg"
            alt="比較してお得"
            loading="lazy"
            decoding="async"
          />
          <p class="c-meritContent_text">
            {{
              PUBLISHED_VALUE.CATEGORIES
            }}種類以上のカテゴリーから豊富なサービスを簡単に比較検討できます。細かな提供方法から評価まであらゆる情報を元にお得に利用できます。
          </p>
        </div>
      </div>
      <div class="c-meritItem">
        <div class="c-meritHeadline">
          <img
            class="c-meritHeadline_tag"
            src="~/assets/images/components/HomeMainContent/CoconalaMerit/tag_point2.svg"
            alt="POINT 2"
            loading="lazy"
            decoding="async"
          />
          <div class="c-meritHeadline_title">オンラインで完結</div>
        </div>
        <div class="c-meritContent">
          <img
            class="c-meritContent_image"
            src="~/assets/images/components/HomeMainContent/CoconalaMerit/coconala_merit2.jpg"
            alt="オンラインで完結"
            loading="lazy"
            decoding="async"
          />
          <p class="c-meritContent_text">
            サービス提供はすべてオンライン上で行います。時間や場所を気にせず、テキスト、ビデオチャット、電話、コンテンツと幅広く取引が可能です。
          </p>
        </div>
      </div>
      <div class="c-meritItem">
        <div class="c-meritHeadline">
          <img
            class="c-meritHeadline_tag"
            src="~/assets/images/components/HomeMainContent/CoconalaMerit/tag_point3.svg"
            alt="POINT 3"
            loading="lazy"
            decoding="async"
          />
          <div class="c-meritHeadline_title">安心・安全</div>
        </div>
        <div class="c-meritContent">
          <img
            class="c-meritContent_image"
            src="~/assets/images/components/HomeMainContent/CoconalaMerit/coconala_merit3.jpg"
            alt="安心・安全"
            loading="lazy"
            decoding="async"
          />
          <p class="c-meritContent_text">
            購入・販売の際のお金のやりとりはココナラが仲介するので安全です。365日運営でのサポートも行っております。
          </p>
        </div>
      </div>
    </div>
    <BeginnerBanner v-if="showBeginnerBanner" />
  </section>
</template>

<script>
import ContentHeadline from '~/components/molecules/ContentHeadline'
import BeginnerBanner from '~/components/molecules/BeginnerBanners'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HomeCoconalaMerit',
  components: {
    ContentHeadline,
    BeginnerBanner
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('constants', ['PUBLISHED_VALUE']),
    showBeginnerBanner() {
      return this.isLoggedIn && this.$translate.showJapaneseOnly()
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

.c-meritList {
  display: flex;
  margin-top: 16px;
}
.c-meritItem {
  flex-basis: 100%;
  &:nth-child(n + 2) {
    margin-left: 24px;
  }
}
.c-meritHeadline {
  display: flex;
  &_tag {
    width: 76px;
    height: 28px;
  }
  &_title {
    margin-left: 20px;
    color: $ds2-color-gray-900;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5em;
  }
}
.c-meritContent {
  display: flex;
  margin-top: 10px;
  &_image {
    width: 80px;
    // 幅 * 画像のアスペクト比
    height: math.div(80px * 80, 105);

    flex-shrink: 0;
  }
  &_text {
    margin-bottom: 0;
    margin-left: 16px;
    text-align: justify;
    line-height: 1.4em;
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-coconalaMerit {
    padding: 0 12px;
  }
  .c-meritList {
    flex-direction: column;
  }
  .c-meritItem {
    &:nth-child(n + 2) {
      margin-top: 20px;
      margin-left: 0;
    }
  }
  .c-meritHeadline {
    &_title {
      font-size: 16px;
    }
  }
}
</style>
